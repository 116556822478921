:root {
  /* header-height: icon-size */
  --header-height: 36px;
  /* footer-height: (font-size * line-height) + padding-top + padding-bottom */
  --footer-height: calc((1.171875rem * 1.5) + 1rem + 1rem);
  /* footer-height: (font-size * line-height) + padding-top + padding-bottom */
  --total-line-height: calc((.9375rem * 1.5) + .25rem + .25rem);
}

html {
  height: 100vh;
}

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  font-family: 'Lato', 'Noto Sans JP', 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', 'MS PGothic', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
  width: 100%;
}

#root {
  height: 100vh;
  width: 100%;
}

.backyard-top {
  margin-top: 100px;
  font-size: small;
}

.liff-top {
  height:100vh;
  /* height: calc(var(--vh, 1vh) * 100); */
  width: 100%;
  overflow: hidden;
  touch-action: none;
  user-select: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/*@page {
  size: 60mm 160mm;
  margin: 0mm;
}*/

.print-body {
  width: 260px;
  height: 700px;
  page-break-after: always;
}

.text-black {
  color:black;
}

.border-black {
  border-color: black!important;
}

.footer {
  /* position: fixed; */
  position: sticky; /* currently in development for MS Edge */
  position: -webkit-sticky;
  position: -moz-sticky;
  position: -o-sticky;
  bottom: 0;
  width: 100%;
}

.header {
  top: 0;
  width: 100%;
}

.table-striped > tbody > tr > th {
  background-color: #ffffff!important;
}

.table-striped-success > tbody > tr:nth-child(2n+1) > td, .table-striped > tbody > tr:nth-child(2n+1) > th {
  background-color: #d2f7f0!important;
}

.table-striped-info > tbody > tr:nth-child(2n+1) > td, .table-striped > tbody > tr:nth-child(2n+1) > th {
  background-color: #cbeaff!important;
}

.btn-submit {
  color: #ffffff;
  border-radius: 0;
  border:none;
  background:linear-gradient(#6d8ff9, #5875cd);
}

.btn-submit-cancel {
  color: #ffffff;
  border-radius: 0;
  border:none;
  background:linear-gradient(#2c2e2d, #1c1e1d);
}

.btn-submit-enable {
  color: #6d8ff9;
  border-radius: 0;
  border:none;
  background:linear-gradient(#ffffff, #ededed);
}

.btn-submit-alert, .btn-submit-alert:hover {
  color: #ffffff;
  border-radius: 0;
  border:none;
  background:linear-gradient(#ffcc31, #f1b400);
}

.btn-submit.disabled, .btn-submit:disabled, .btn-submit-cancel.disabled, .btn-submit-cancel:disabled, .btn-submit-danger.disabled, .btn-submit-danger:disabled, .btn-submit-alert.disabled, .btn-submit-alert:disabled {
  color: #fff;
  background:linear-gradient(#b0b0b0, #9e9e9e);
  border: none;
  border-radius: 0;
}

.table-bordered-success {
  border: 1px solid #14977d;
}

.table-bordered-info {
  border: 1px solid #2875a8;
}

.table-bordered-success th, .table-bordered-success td{
  border: 1px solid #14977d;
}

.table-bordered-info th, .table-bordered-info td{
  border: 1px solid #2875a8;
}
.table-bordered-success thead td, .table-bordered-success thead th {
  border-bottom-width: 2px;
}
.table-bordered-info thead td, .table-bordered-info thead th {
  border-bottom-width: 2px;
}

.table-scroll {
  overflow-y: scroll;
}

.table-updated{
  background-color: #beece3!important;
}

.w-20 {
  width: 20%!important;
}

.bg-lightwhite {
  /* background-color: #e6e6e6 !important; */
  background: linear-gradient(130deg, #f0b7da, white, #d4f5fc);
}

.bg-ash {
  background-color: #e6e6e6 !important;
}

.bg-lightblue {
  background-color: #deebf7;
}

.bg-darkblack {
  background-color: #5e5f5e !important;
}

.bg-black {
  background-color: black !important;
}

.bg-call-head {
  background-color: dodgerblue !important;
  color: white;
}

.bg-absence-head {
  background-color: royalblue !important;
  color: white;
}

.bg-absence {
  background-color: lightgoldenrodyellow !important;
}

.table-call, .table-absence {
  border-collapse: separate;
  border-spacing: 4px;
}

.table-call > tbody > tr:nth-child(2n+1) > td {
  background-color: white !important;
}
.table-call > tbody > tr:nth-child(2n) > td {
  background-color: white !important;
}

.waiting, .call-head, .absence-head, .call, .absence, .message {
  font-weight: bold;
  white-space: pre;
}

.call {
  font-size: 4.5rem;
}

.absence {
  font-size: 4.5rem;
}

.section {
  background-color: #658ff9;
  color: white;
  text-align: center!important;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 0rem;
  padding-right: 0rem;
}

.section span {
  color:white;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.section-dark {
  background-color: #5e5f5e;
  color: white;
  text-align: center!important;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.section-top {
  height: 2.40625rem;
  overflow: hidden;
}

.border-success {
  border-color: #18bc9c;
}

.border-info {
  border-color: #3498db;
}

.border-darkblack {
  border-color: #5e5f5e!important;
}

.border-success-bold {
  border-color: #18bc9c!important;
  border-width: 2px!important;
}

.border-info-bold {
  border-color: #3498db!important;
  border-width: 2px!important;
}

.border-cell {
  border-color: #c3c3c3!important;
  border-width: 2px!important;
}

.btn-active {
  color: #ffffff;
  background:linear-gradient(#6d8ff9, #5875cd);
  /*border-color: #329763;*/
}

.btn-enable {
  color: #212529;
  background:linear-gradient(#ffffff, #ededed);
  border-color: #658ff9;
  border-width: 1px;
}

.btn-enable:disabled, .btn-enable.disabled, .btn-active:disabled, .btn-active.disabled {
  color: #ffffff;
  background:linear-gradient(#b0b0b0, #9e9e9e);
  /*border-color: #329763;*/
}

.btn-active:hover {
  color: #ffffff;
}

.btn-enable:hover {
  color: #212529;
}

.btn-alert {
  color: #ffffff;
  background:linear-gradient(#ffc000, #f8ba00);
  /*border-color: #329763;*/
}

.icon {
  width: 20px;
  height: 20px;
}

.arrow {
  width: 24px;
  height: 24px;
}

.arrow-lg {
  width: 3rem;
  height: 3rem;
}

.btn, .btn-lg {
  font-weight: bold;
}

.ReactModal__Body--open {
  position: fixed;
}

.loading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.5);
}

.spinner {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}

.icon-lg {
  width: 60px;
  height: 60px;
}

.btn-submit-circle {
  color: #fff100;
  border-radius: 50%;
  border:none;
  width: 155px;
  height:155px;
  padding: 0px;
  background:linear-gradient(#11aa66, #0b8951);
}

.btn-submit-cancel-circle {
  color: #ffffff;
  border-radius: 50%;
  border:none;
  width: 155px;
  height:155px;
  padding: 0px;
  background:linear-gradient(#2c2e2d, #1c1e1d);
}

.btn-submit-circle.disabled, .btn-submit-circle:disabled, .btn-submit-cancel-circle.disabled, .btn-submit-cancel-circle:disabled {
  color: #fff;
  background:linear-gradient(#b0b0b0, #9e9e9e);
  border: none;
  border-radius: 50%;
}

.p-env-bottom {
  padding-bottom: 1rem!important;
}

@supports(padding:max(0px)) {
  body, header, footer {
      padding-left: min(0vmin, env(safe-area-inset-left));
      padding-right: min(0vmin, env(safe-area-inset-right));
      padding-top: min(0vmin, env(safe-area-inset-top));
      padding-bottom: min(0vmin, env(safe-area-inset-bottom));
  }
  .p-env-bottom {
    padding-bottom: max(1rem, env(safe-area-inset-bottom))!important;
  }
}

@supports(margin:max(0px)) {
  body, header, footer {
    margin-left: min(0vmin, env(safe-area-inset-left));
    margin-right: min(0vmin, env(safe-area-inset-right));
    margin-top: min(0vmin, env(safe-area-inset-top));
    margin-bottom: min(0vmin, env(safe-area-inset-bottom));
  }
}

.line-height-2-2 {
  line-height: 2.2rem;
}

.font-h4 {
  font-size: 1.40625rem;
}

.px-0-env {
  padding-left: calc(0rem + env(safe-area-inset-left))!important;
  padding-right: calc(0rem + env(safe-area-inset-right))!important;
}
.px-1-env {
  padding-left: calc(0.25rem + env(safe-area-inset-left))!important;
  padding-right: calc(0.25rem + env(safe-area-inset-right))!important;
}
.px-2-env {
  padding-left: calc(0.5rem + env(safe-area-inset-left))!important;
  padding-right: calc(0.5rem + env(safe-area-inset-right))!important;
}
.px-3-env {
  padding-left: calc(1rem + env(safe-area-inset-left))!important;
  padding-right: calc(1rem + env(safe-area-inset-right))!important;
}

.page-btn {
  /* margin-top: 2.40625rem; */
  height: calc(100% - var(--header-height) - var(--footer-height));
  max-height: calc(100% - var(--header-height) - var(--footer-height) + 1rem - env(safe-area-inset-bottom, 1rem));
  /* height: calc(100% - 2.7578125rem + 0.1015625rem - max(1rem, env(safe-area-inset-bottom))); */
  /* height: calc(100% - 2.40625rem); */
  overflow-y: scroll;
  overscroll-behavior: none;
  --webkit-overflow-scrolling: touch;
}
.page-btn-total {
  /* margin-top: 2.40625rem; */
  height: calc(100% - var(--header-height) - var(--footer-height) - var(--total-line-height));
  max-height: calc(100% - var(--header-height) - var(--footer-height) - var(--total-line-height) + 1rem - env(safe-area-inset-bottom));
  /* height: calc(100% - 2.7578125rem + 0.1015625rem - max(1rem, env(safe-area-inset-bottom))); */
  /* height: calc(100% - 2.40625rem); */
  overflow-y: scroll;
  overscroll-behavior: none;
  --webkit-overflow-scrolling: touch;
}
.page-full {
  /* margin-top: 2.40625rem; */
  max-height: calc(100% - 2.40625rem);
  /* max-height: calc(100%); */
  overflow-y: scroll;
  overscroll-behavior: none;
  --webkit-overflow-scrolling: touch;
}

.bg-line {
  background-color: #20ab3d;
}

.section-line {
  background-color: #20ab3d;
  color: white;
  text-align: center!important;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 0rem;
  padding-right: 0rem;
}

.text-line {
  color:#fff100;
}

.btn-blue-active {
  color: #ffffff;
  background:linear-gradient(#1353df, #1043b1);
  /*border-color: #329763;*/
}

.btn-blue-enable {
  color: #1353df;
  background:linear-gradient(#ffffff, #ededed);
  /*border-color: #329763;*/
}

.btn-blue-enable:disabled, .btn-blue-enable.disabled, .btn-blue-active:disabled, .btn-blue-active.disabled {
  color: #ffffff;
  background:linear-gradient(#b0b0b0, #9e9e9e);
  /*border-color: #329763;*/
}

.btn-blue-active:hover {
  color: #ffffff;
}

.btn-blue-enable:hover {
  color: #1353df;
}

.btn-blue-submit {
  color: #ffffff;
  border-radius: 0;
  border:none;
  background:linear-gradient(#1353df, #1043b1);
}

.btn-blue-submit.disabled, .btn-blue-submit:disabled, .btn-blue-submit-cancel.disabled, .btn-blue-submit-cancel:disabled {
  color: #fff;
  background:linear-gradient(#b0b0b0, #9e9e9e);
  border: none;
  border-radius: 0;
}

.blink {
  animation: blinkAnime 0.5s infinite alternate;
}
@keyframes blinkAnime{
   0% { color: #212529; opacity: 0 }
  24% { color: #212529; opacity: 0 }
  25% { color: #212529; opacity: 1 }
 100% { color: #212529; opacity: 1 }
}

.tab-navs {
  overflow-x: scroll;
}

.tab-list {
  width: 90em;
}

.text-font{
  padding: .5rem 1rem;
font-size: 2.4rem;
line-height: 1.2;
border-radius: .3rem;  
font-weight: 500;
}

.section-white {
  background-color: white;
  text-align: center!important;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 0rem;
  padding-right: 0rem;
}

.bg-gray-alpha {
  background-color: rgba(160,160,160,0.5);
}

@media (min-width: 1400px) {
  .container,.container-lg,.container-md,.container-sm,.container-xl {
      max-width:1340px
  }
}

@media (min-width: 1600px) {
  .container,.container-lg,.container-md,.container-sm,.container-xl {
      max-width:1540px
  }
}

@media (min-width: 1920px) {
  .container,.container-lg,.container-md,.container-sm,.container-xl {
      max-width:1840px
  }
}

.sticky_table {
  width: unset;
}

.error {
  color: #ff0000;
}

.sticky_table thead th {
  /* 縦スクロール時に固定する */
  position: -webkit-sticky;
  position: sticky;
  top: 50px;
  /* tbody内のセルより手前に表示する */
  z-index: 1;
  background-color: #3498db!important;
  min-width: 3em;
  width: 3em;
  line-height: 1.1rem;
  text-align: center;
}

.sticky_table thead th span {
  /* 縦書き */
  -ms-writing-mode: tb-rl;
  writing-mode: vertical-rl;
  text-orientation:upright;
}

.sticky_table th:first-child {
  /* 横スクロール時に固定する */
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  white-space: nowrap;
}

.sticky_table thead th:first-child {
  /* 横スクロール時に固定する */
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  /* ヘッダー行内の他のセルより手前に表示する */
  z-index: 2;
  background-color: #3498db!important;
}

.sticky_table .total {
  border-left: 2px solid lightgray;
  border-right: 2px solid lightgray;
  font-weight: bold;
}

.react-datepicker-popper {
  z-index: 999!important;
}

.react-datepicker-wrapper {
  width: 100%;
}

.border-textbox {
  border: 1px solid #658ff9;
  border-radius: 3px;
}

.mx--1 {
  margin-left: -0.25rem;
  margin-right: -0.25rem;
}
.input-number-w120{
  width: 120px;
}
.w50px{
  width: 50px;
}
.backyard-top .form-control.is-invalid, .was-validated .form-control:invalid,
.custom-select.is-invalid, .was-validated .custom-select:invalid{
  background-image:none;
  padding-right:0.8em;
}

.like-button {
  -ms-user-select: none; /* IE 10+ */
  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
}
.productpreview-modal{
  width: 375px; /* iPhoneX size*/
  height: 80vh;
  max-height: 812px; /* iPhoneX size*/
}
input[type="date"]{
  background-color: #FFFFFF;
}
input[type="date"]::-webkit-inner-spin-button{
  display: none;
}
input[type="date"]::-webkit-clear-button{
  display: none;
}
input[type="date"]::-webkit-calendar-picker-indicator{
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  padding: 0;
  background: transparent;
  color: transparent;
  cursor: pointer;
  width: 100%;
}
.order-datepicker .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle, 
.order-datepicker .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before{
  border: 0px;
}
@page{
  margin: 0mm;
}
@media print {
  body{
    -webkit-print-color-adjust: exact;
  }
  .print-list{
    page-break-after: avoid;
    font-size:small !important;
    padding: 8mm;
  }

  .print-none{
    display: none;
  }
  .table-striped > thead > tr > th{
    background-color:#cbeaff!important;
    color: #000 !important;
    border-color:#000000 !important;
  }
  .table-striped > tbody > tr > td{
    border-color:#000000 !important;
  }
  .table-striped > tbody > tr:nth-child(2n+1) > td{
    background-color:#cccccc !important;
    color: #000 !important;    
  }
  .table-striped > tbody > tr > td > table > tr > td{
    background-color:transparent!important;
  }
  .table-dept > thead > tr > th{
    background-color:#cbeaff!important;
    color: #000 !important;
    border-color:#000000 !important;
  }
  .table-dept > tbody > tr > td{
    border-color:#000000 !important;
  }
  
  .print-view{
    display: block;
  }

}